
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    label: {
      type: String,
    },
    visible: {
      type: Boolean,
    },
    showVisibility: {
      type: Boolean,
    },
  },
  emits: ['update:label', 'update:visible'],
})
