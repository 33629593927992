
import { defineComponent, ref } from 'vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import TmButton from '@/components/shared/TmButton.vue'
import FlowStepList from '@/components/shared/templates/FlowStepList.vue'
import AppearanceStep from '@/components/pages/services/forms/flows/clicks/AppearanceStep.vue'
import EmbedCodeStep from '@/components/pages/services/forms/flows/clicks/EmbedCodeStep.vue'
import AdditionalSettingsStep from '@/components/pages/services/forms/flows/clicks/AdditionalSettingsStep.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: { FlowStepList, TmButton, DetailsHero, PageBlock, AppearanceStep, EmbedCodeStep, AdditionalSettingsStep },
  props: {
    title: {
      type: String,
      default: 'New Click-to-text form',
    },
  },
  setup(props) {
    const breadcrumbs = [
      { label: 'Forms', name: 'base.services.forms' },
      { label: props.title },
    ]
    const { isMdMax } = useBreakpoints()
    const currentStep = ref(1)

    return {
      isMdMax,
      breadcrumbs,
      currentStep,
    }
  },
})
