
import { defineComponent } from 'vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmExpansion,
  },
  props: {
    icon: {
      type: String,
    },
    label: {
      type: String,
    },
    caption: {
      type: String,
    },
  },
  setup() {
    const { isSmMax } = useBreakpoints()

    return {
      isSmMax,
    }
  },
})
