
import { defineComponent, ref } from 'vue'
import type { SizeProp, IconSize } from '@/definitions/shared/types'
import kebabCase from 'lodash/kebabCase'

export default defineComponent({
  name: 'TmExpansion',
  props: {
    label: {
      type: String,
    },
    caption: {
      type: String,
    },
    captionClass: {
      type: String,
      default: '',
    },
    expanded: {
      type: Boolean,
    },
    modelValue: {
      type: Boolean,
      default: null,
    },
    group: {
      type: String,
    },
    headerClass: {
      type: String,
    },
    hidableBtn: {
      type: Boolean,
    },
    hideExpansionBtn: {
      type: Boolean,
    },
    hideBorder: {
      type: Boolean,
    },
    iconSize: {
      type: String as SizeProp<IconSize>,
      default: 'medium',
    },
    size: {
      type: String as SizeProp<'' | 'small' | 'medium'>,
      default: '',
    },
    contentPadding: {
      type: Boolean,
    },
    icon: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
    labelClass: {
      type: String,
      default: '',
    },
    facet: {
      type: Number,
    },
    duration: {
      type: Number,
    },
    faqView: {
      type: Boolean,
    },
    draggable: {
      type: Boolean,
    },
    toggleValue: {
      type: Boolean,
      default: null,
    },
    cardRowView: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'update:toggleValue'],
  setup(props, context) {
    const state = ref(props.expanded)
    const expansionRef = ref()
    const hasBtnSlot = !!context.slots['tm-expansion-btn']
    const hasLabelAfterSlot = !!context.slots['label-after']

    const show = () => {
      expansionRef.value.show()
    }

    return {
      show,
      expansionRef,
      state,
      hasBtnSlot,
      hasLabelAfterSlot,
      kebabCase,
    }
  },
})
