import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-690a625b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "finish-block" }
const _hoisted_2 = { class: "finish-block__title" }
const _hoisted_3 = { class: "page-title-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_tm_icon, {
        name: "tmi-task-circle",
        "custom-size": "28px",
        class: "success--text"
      }),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1)
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}