import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a8de07f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "preview-expanded__number"
}
const _hoisted_2 = { class: "preview-expanded__description" }
const _hoisted_3 = { class: "subhead-semi-bold-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_form_line = _resolveComponent("tm-form-line")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_preview_layout = _resolveComponent("preview-layout")!

  return (_openBlock(), _createBlock(_component_preview_layout, null, {
    default: _withCtx(() => [
      (_ctx.numberVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.textMagicNumber.formattedNumber), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.description), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldsComputed, (field, i) => {
        return (_openBlock(), _createBlock(_component_tm_form_line, {
          key: i,
          class: _normalizeClass({
        'mt-2': i,
      }),
          label: field.label
        }, {
          default: _withCtx(() => [
            _createVNode(_component_tm_field, {
              "model-value": "",
              placeholder: field.placeholder,
              validators: "required"
            }, null, 8, ["placeholder"])
          ]),
          _: 2
        }, 1032, ["class", "label"]))
      }), 128)),
      _createVNode(_component_tm_button, {
        class: "w100pr mt-4",
        size: "large",
        color: "primary"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.btnText), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}