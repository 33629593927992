
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { copyToClipboard } from '@/services/utils'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import CodeSnippet from '@/components/shared/templates/CodeSnippet.vue'
import { codeExample } from '@/definitions/_general/_data/code'

export default defineComponent({
  components: {
    TmButton,
    CodeSnippet,
    TmTooltip,
  },
  props: {
    code: {
      type: String,
      default: codeExample,
    },
  },
  setup() {
    const copied = ref(false)
    const copyFunc = (text: string) => {
      copied.value = true
      copyToClipboard(text)
    }

    return {
      copied,
      copyFunc,
    }
  },
})
