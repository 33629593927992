
import { defineComponent, ref } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import PreviewExpanded from '@/components/pages/services/forms/flows/clicks/appearance/PreviewExpanded.vue'
import PreviewMinimized from '@/components/pages/services/forms/flows/clicks/appearance/PreviewMinimized.vue'
import PreviewCompleted from '@/components/pages/services/forms/flows/clicks/appearance/PreviewCompleted.vue'
import FlowField from '@/components/pages/services/forms/flows/FlowField.vue'
import type { NumberType } from '@/definitions/services/forms/types'

export default defineComponent({
  components: {
    PreviewCompleted,
    FlowField,
    PreviewMinimized,
    TmFormLine,
    PreviewExpanded,
    FlowStep,
  },
  setup() {
    const previewType = ref<'expanded' | 'minimized' | 'completed'>('expanded')
    const numberList = ref<NumberType[]>([
      {
        number: '+12563651288',
        label: 'USA Support',
        formattedNumber: '(256) 365-1288',
      },
      {
        number: '+111145523',
        label: 'USA Support',
        formattedNumber: '(422) 114-5523',
      },
      {
        number: '+13144445123',
        label: 'USA Support',
        formattedNumber: '(314) 444-5123',
      },
      {
        number: '+15533213429',
        label: 'USA Support',
        formattedNumber: '(553) 321-3429',
      },
      {
        number: '+13100421452',
        label: 'USA Support',
        formattedNumber: '(310) 042-1452',
      },
      {
        number: '+12700239455',
        label: 'USA Support',
        formattedNumber: '(270) 023-9455',
      },
    ])
    const state = ref({
      headerText: 'Questions? Text us now!',
      textMagicNumber: numberList.value[0],
      description: 'Text this number or fill in the form and we will reply to you as soon as possible.',
      btnText: 'Send text message',
      successHeading: 'Thank you for contacting us!',
      successDescription: 'Our experts are already dealing with your question. You will receive a reply maximum during 1 business day.',
      position: 'Right',
      numberVisible: true,
      headerColor: '#008CFF',
      numberColor: '#008CFF',
      buttonColor: '#008CFF',
      fields: [
        {
          label: 'Your name',
          placeholder: 'Enter your full name',
          visible: true,
          showVisibility: true,
        },
        {
          label: 'Your mobile number',
          placeholder: 'Enter your mobile number',
          visible: true,
          showVisibility: false,
        },
        {
          label: 'Your message',
          placeholder: 'Enter your message',
          visible: true,
          showVisibility: false,
        },
      ],
    })
    const positionList = ref<string[]>([
      'Right',
      'Bottom',
      'Left',
      'Top',
    ])
    const fields = ref<string[]>([
      'Your name',
      'Your mobile number',
      'Your message',
    ])
    return {
      numberList,
      fields,
      previewType,
      state,
      positionList,
    }
  },
})
