
import { defineComponent } from 'vue'
import PreviewHeader from '@/components/pages/services/forms/flows/clicks/appearance/preview/PreviewHeader.vue'
import PreviewFooter from '@/components/pages/services/forms/flows/clicks/appearance/preview/PreviewFooter.vue'

export default defineComponent({
  components: {
    PreviewHeader,
    PreviewFooter,
  },
  props: {
    headerText: {
      type: String,
    },
  },
})
