<template>
  <div class="preview-footer">
    <div>
      Powered by
    </div>
    <img src="@/assets/images/logo-with-text.svg">
  </div>
</template>

<style scoped lang="scss">
.preview-footer {
  height: 24px;
  background: $shade;
  border-radius: 0 0 $border-radius-lg $border-radius-lg;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $light;
  font-weight: $semi-bold-font;

  img {
    height: 12px;
    margin-top: 3px;
    margin-left: 5px;
  }
}
</style>
