
import { computed, defineComponent, watch, ref } from 'vue'
import { useScroll } from '@/compositions/rootScroll'
import TmButton from '@/components/shared/TmButton.vue'
import { styleVars } from '@/compositions/styleVariables'

export default defineComponent({
  name: 'FlowStep',
  components: {
    TmButton,
  },
  props: {
    showContentAfterFinished: {
      type: Boolean,
    },
    hideEditButton: {
      type: Boolean,
    },
    hideContinueButton: {
      type: Boolean,
    },
    step: {
      type: Number,
      default: 1,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    continueButton: {
      type: String,
      default: 'Continue',
    },
    editButton: {
      type: String,
    },
    editButtonIcon: {
      type: String,
      default: 'edit',
    },
    hideNextSubtitle: {
      type: Boolean,
    },
  },
  emits: ['step-change', 'step-active'],
  setup(props, context) {
    const { rootScroll } = useScroll()
    const root = ref<HTMLDivElement | null>(null)

    const isActive = computed(() => props.step === props.currentStep)
    const isFinished = computed(() => props.currentStep > props.step)
    const isNext = computed(() => !isActive.value && !isFinished.value)
    const showContent = computed(() => isActive.value || (props.showContentAfterFinished && !isNext.value))
    const hasSubtitleSlot = context.slots.subtitle
    const nextStep = () => context.emit('step-change', props.step + 1)

    const alignTop = (el: HTMLElement) => {
      if (!el || !rootScroll.value) return
      const scrollGap = 8
      setTimeout(() => {
        const { top } = el.getBoundingClientRect()
        rootScroll.value.scrollTop = rootScroll.value.scrollTop + top - styleVars.headerHeight - scrollGap
      }, 0)
    }

    watch(isActive, async (newVal) => {
      if (newVal && root.value) {
        context.emit('step-active')
        alignTop(root.value)
      }
    })

    return {
      root,
      isActive,
      isFinished,
      isNext,
      showContent,
      hasSubtitleSlot,
      nextStep,
    }
  },
})
