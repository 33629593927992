
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmBreadcrumbs from '@/components/shared/responsive/breadcrumbs/TmBreadcrumbs.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmSkeleton from '@/components/shared/TmSkeleton.vue'
import PageScrollContainer from '@/components/layout/PageScrollContainer.vue'
import TmBreadcrumbsLoading from '@/components/shared/templates/loading/TmBreadcrumbsLoading.vue'
import type { BreadcrumbsLink } from '@/definitions/shared/types'
import { useModes } from '@/compositions/modes'
import kebabCase from 'lodash/kebabCase'

export default defineComponent({
  components: { PageScrollContainer, TmBreadcrumbs, TmButton, TmDropdown, TmSkeleton, TmBreadcrumbsLoading },
  props: {
    wideLeft: {
      type: Boolean,
    },
    size: {
      type: String as PropType<'' | 'medium' | 'wide' | 'full'>,
    },
    title: {
      type: String,
    },
    breadcrumbs: {
      type: Array as PropType<BreadcrumbsLink[]>,
    },
  },
  setup(props, context) {
    const { isLoadingMode } = useModes()
    const hasBlocksLeftSlot = !!context.slots['page-blocks-left']
    const hasWhiteWrapperSlot = !!context.slots['white-wrapper']
    const hasTitleActionsSlot = !!context.slots['title-actions']

    return {
      hasBlocksLeftSlot,
      hasWhiteWrapperSlot,
      hasTitleActionsSlot,
      isLoadingMode,
      kebabCase,
    }
  },
})
