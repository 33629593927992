import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a6327ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "preview-completed__wrapper" }
const _hoisted_2 = { class: "preview-completed__title" }
const _hoisted_3 = { class: "body-text-semi-bold-14 distinct--text lh-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_preview_layout = _resolveComponent("preview-layout")!

  return (_openBlock(), _createBlock(_component_preview_layout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_icon, {
          name: "check_circle_outline",
          "custom-size": _ctx.isSmMax ? '80px' : '100px',
          class: "mb-1 success--text"
        }, null, 8, ["custom-size"]),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.successHeading), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.successDescription), 1)
      ])
    ]),
    _: 1
  }))
}