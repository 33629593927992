import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a25d16f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flow-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_icon, {
      name: "format_list_bulleted",
      class: "distinct--text mr-2",
      size: "xxLarge"
    }),
    _createVNode(_component_tm_field, {
      "model-value": _ctx.label,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:label', $event)))
    }, null, 8, ["model-value"]),
    (_ctx.showVisibility)
      ? (_openBlock(), _createBlock(_component_tm_icon, {
          key: 0,
          name: _ctx.visible ? 'visibility' : 'visibility_off',
          size: "xLarge",
          class: "flow-field__visibility",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:visible', !_ctx.visible)))
        }, null, 8, ["name"]))
      : _createCommentVNode("", true)
  ]))
}