
import { defineComponent } from 'vue'
import PreviewLayout from '@/components/pages/services/forms/flows/clicks/appearance/preview/PreviewLayout.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    PreviewLayout,
  },
  props: {
    successHeading: {
      type: String,
    },
    successDescription: {
      type: String,
    },
  },
  setup() {
    const { isSmMax } = useBreakpoints()

    return {
      isSmMax,
    }
  },
})
