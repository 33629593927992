
import { defineComponent } from 'vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmExpansion,
  },
  props: {
    toggleValue: {
      type: Boolean,
      default: null,
    },
    draggable: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    caption: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
  emits: ['update:toggleValue'],
  setup() {
    const { isSmMax } = useBreakpoints()

    return {
      isSmMax,
    }
  },
})
