import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6cd4ac7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-blocks" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex align-center justify-between"
}
const _hoisted_3 = {
  key: 1,
  class: "d-flex"
}
const _hoisted_4 = { class: "page-blocks__left" }
const _hoisted_5 = { class: "page-blocks__right" }
const _hoisted_6 = {
  key: 2,
  class: "page-blocks__wrapper"
}
const _hoisted_7 = {
  key: 0,
  class: "d-flex align-center mb-6"
}
const _hoisted_8 = { class: "page-title-20 d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_breadcrumbs = _resolveComponent("tm-breadcrumbs")!
  const _component_tm_breadcrumbs_loading = _resolveComponent("tm-breadcrumbs-loading")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!
  const _component_tm_skeleton = _resolveComponent("tm-skeleton")!
  const _component_page_scroll_container = _resolveComponent("page-scroll-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_page_scroll_container, null, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["page-blocks__content", {
          'page-blocks__content--left-wide': _ctx.wideLeft,
          [`page-blocks__content--${_ctx.kebabCase(_ctx.size)}`]: _ctx.size,
        }])
        }, [
          (_ctx.breadcrumbs)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (!_ctx.isLoadingMode)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_tm_breadcrumbs, {
                        links: _ctx.breadcrumbs,
                        class: "my-2"
                      }, null, 8, ["links"]),
                      _renderSlot(_ctx.$slots, "page-breadcrumbs-right", {}, undefined, true)
                    ], 64))
                  : (_openBlock(), _createBlock(_component_tm_breadcrumbs_loading, {
                      key: 1,
                      class: "my-5"
                    }))
              ]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "page-blocks-top", {}, undefined, true),
          (_ctx.hasBlocksLeftSlot)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "page-blocks-left", {}, undefined, true)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _renderSlot(_ctx.$slots, "page-blocks-right", {}, undefined, true)
                ])
              ]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true),
          (_ctx.hasWhiteWrapperSlot)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (!_ctx.isLoadingMode)
                  ? _renderSlot(_ctx.$slots, "title", {
                      key: 0,
                      title: _ctx.title
                    }, () => [
                      (_ctx.title)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createElementVNode("div", _hoisted_8, [
                              _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
                              (_ctx.hasTitleActionsSlot)
                                ? (_openBlock(), _createBlock(_component_tm_dropdown, {
                                    key: 0,
                                    class: "d-flex"
                                  }, {
                                    menu: _withCtx(() => [
                                      _renderSlot(_ctx.$slots, "title-actions", {}, undefined, true)
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_tm_button, {
                                        class: "ml-1",
                                        size: "xSmall",
                                        "icon-only": "",
                                        flat: "",
                                        color: "transparent"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_tm_icon, {
                                            class: "distinct--text",
                                            name: "tmi-arrow-drop-down-sharp"
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 3
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _renderSlot(_ctx.$slots, "title-info", {}, undefined, true)
                          ]))
                        : _createCommentVNode("", true)
                    ], true)
                  : (_openBlock(), _createBlock(_component_tm_skeleton, {
                      key: 1,
                      type: "QChip",
                      width: "232px",
                      height: "20px",
                      class: "mb-7"
                    })),
                _renderSlot(_ctx.$slots, "white-wrapper", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      _: 3
    })
  ]))
}