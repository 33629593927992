
import { defineComponent } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import FinishBlock from '@/components/shared/templates/FinishBlock.vue'
import CodeTag from '@/components/shared/templates/CodeTag.vue'
import TmButton from '@/components/shared/TmButton.vue'
import EmbedCode from '@/components/shared/templates/EmbedCode.vue'

export default defineComponent({
  components: {
    FinishBlock,
    TmButton,
    FlowStep,
    CodeTag,
    EmbedCode,
  },
})
