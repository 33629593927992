import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cd902322"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "root",
  class: "flow-step"
}
const _hoisted_2 = { class: "flow-step__header-container" }
const _hoisted_3 = { class: "flow-step__number-box" }
const _hoisted_4 = { class: "flow-step__number-mobile" }
const _hoisted_5 = { class: "flow-step__title-container" }
const _hoisted_6 = { class: "flex-grow-1" }
const _hoisted_7 = {
  key: 0,
  class: "flow-step__edit-container"
}
const _hoisted_8 = {
  key: 0,
  class: "flow-step__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["flow-step__number", {
            'emphasize--text': _ctx.isActive,
            'light--text': _ctx.isNext,
            'flow-step__number--finished': _ctx.isFinished,
          }])
        }, [
          (_ctx.isFinished)
            ? (_openBlock(), _createBlock(_component_tm_icon, {
                key: 0,
                size: "xLarge",
                name: "done",
                class: "success--text"
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.step), 1)
              ], 64))
        ], 2),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.isFinished)
            ? (_openBlock(), _createBlock(_component_tm_icon, {
                key: 0,
                name: "check_circle",
                size: "small",
                class: "mr-1 success--text"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("span", {
            class: _normalizeClass(["caption-12 lh-16", {
              'success--text': _ctx.isFinished,
              'light--text': _ctx.isActive,
              'gray4--text': _ctx.isNext,
            }])
          }, " step " + _toDisplayString(_ctx.step), 3)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            class: _normalizeClass(["flow-step__title", {
              'emphasize--text': _ctx.isActive || _ctx.isFinished,
              'light--text': _ctx.isNext,
            }])
          }, [
            _renderSlot(_ctx.$slots, "title", _normalizeProps(_guardReactiveProps({ isActive: _ctx.isActive, isFinished: _ctx.isFinished, isNext: _ctx.isNext })), () => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ], true)
          ], 2),
          (_ctx.$slots.subtitle && !_ctx.isNext)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["body-text-regular-14 lh-20 pr-4", {
              'neutral--text': _ctx.isActive || _ctx.isFinished,
              'light--text': _ctx.isNext,
            }])
              }, [
                _renderSlot(_ctx.$slots, "subtitle", _normalizeProps(_guardReactiveProps({ isActive: _ctx.isActive, isFinished: _ctx.isFinished, isNext: _ctx.isNext })), () => [
                  _createTextVNode(_toDisplayString(_ctx.subtitle), 1)
                ], true)
              ], 2))
            : _createCommentVNode("", true)
        ]),
        (!_ctx.hideEditButton && _ctx.editButton && !_ctx.isActive && !_ctx.isNext)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_tm_button, {
                size: "large",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('step-change', _ctx.step)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_tm_icon, {
                    left: "",
                    name: _ctx.editButtonIcon
                  }, null, 8, ["name"]),
                  _createElementVNode("span", null, _toDisplayString(_ctx.editButton), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.showContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({ isActive: _ctx.isActive, isFinished: _ctx.isFinished, isNext: _ctx.isNext })), undefined, true),
          _renderSlot(_ctx.$slots, "footer", _normalizeProps(_guardReactiveProps({ isActive: _ctx.isActive, isFinished: _ctx.isFinished, isNext: _ctx.isNext, nextStep: _ctx.nextStep })), () => [
            (!_ctx.hideContinueButton && _ctx.isActive)
              ? (_openBlock(), _createBlock(_component_tm_button, {
                  key: 0,
                  color: "primary",
                  size: "large",
                  onClick: _ctx.nextStep
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.continueButton), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ], true)
        ]))
      : _createCommentVNode("", true)
  ], 512))
}