
import { defineComponent, computed } from 'vue'
import TmSkeleton from '@/components/shared/TmSkeleton.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmSkeleton,
  },
  setup() {
    const { isSmMax } = useBreakpoints()
    const counter = computed(() => isSmMax.value ? 1 : 3)

    return {
      counter,
    }
  },
})
