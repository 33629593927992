
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmExpansionWhitePanel from '@/components/shared/tmExpansion/TmExpansionWhitePanel.vue'
import TmExpansionPanel from '@/components/shared/tmExpansion/TmExpansionPanel.vue'
import phoneCountryVariant from '@/definitions/_general/_data/countryVariant'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
    TmExpansionPanel,
    TmExpansionWhitePanel,
    TmFormLine,
  },
  setup() {
    const optInValue = ref(true)
    const country = ref(phoneCountryVariant[0])
    const lists = ref('- Please select a list -')
    const listsOptions = [
      'Specific',
      'Marketing',
      'Support',
    ]
    const welcomeMessage = ref('Сompany: Reply YES to confirm receiving SMS/MMS messages. SMS terms: https://www./terms-of-use\nReply “STOP” to opt out')

    return {
      welcomeMessage,
      listsOptions,
      lists,
      optInValue,
      country,
      phoneCountryVariant,
    }
  },
})
