import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cf11e4c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-grow-1 flex-basis-0 flex-shrink-1" }
const _hoisted_2 = { class: "d-flex align-center" }
const _hoisted_3 = {
  key: 0,
  class: "tm-expansion__header-icon"
}
const _hoisted_4 = { class: "tm-expansion__header-left" }
const _hoisted_5 = {
  key: 1,
  class: "tm-expansion__facet"
}
const _hoisted_6 = {
  key: 2,
  class: "d-inline-flex ml-2"
}
const _hoisted_7 = {
  key: 2,
  class: "tm-expansion__btn flex-grow-0 flex-shrink-0 ml-3 mr-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-expansion", {
      'tm-expansion--hidable-btn': _ctx.hidableBtn,
      'tm-expansion--hide-expansion-btn': _ctx.hideExpansionBtn,
      'tm-expansion--hide-border': _ctx.hideBorder,
      [`tm-expansion--${_ctx.kebabCase(_ctx.size)}`]: _ctx.size,
      'tm-expansion--with-icon': _ctx.icon,
      'tm-expansion--faq': _ctx.faqView,
      'tm-expansion--card-row': _ctx.cardRowView,
      'tm-expansion--toggle-disabled': _ctx.toggleValue !== null && !_ctx.toggleValue,
      'tm-expansion--collapsed': _ctx.modelValue !== null ? !_ctx.modelValue : !_ctx.state,
    }])
  }, [
    _createVNode(_component_q_expansion_item, {
      ref: "expansionRef",
      "model-value": _ctx.modelValue !== null ? _ctx.modelValue : _ctx.state,
      "default-opened": _ctx.expanded,
      group: _ctx.group,
      duration: _ctx.duration,
      "header-class": _ctx.headerClass,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.modelValue !== null ? _ctx.$emit('update:modelValue', $event) : _ctx.state = $event))
    }, {
      header: _withCtx(() => [
        _renderSlot(_ctx.$slots, "header", {}, () => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.cardRowView && _ctx.icon)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_tm_icon, {
                      name: _ctx.icon,
                      size: "xxLarge",
                      class: "neutral--text"
                    }, null, 8, ["name"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: _normalizeClass(["q-item__label", _ctx.labelClass])
                }, [
                  (!_ctx.cardRowView)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_ctx.draggable)
                          ? (_openBlock(), _createBlock(_component_tm_icon, {
                              key: 0,
                              name: "drag_indicator",
                              size: "xxxLarge",
                              class: "light--text mr-4"
                            }))
                          : (_ctx.icon)
                            ? (_openBlock(), _createBlock(_component_tm_icon, {
                                key: 1,
                                name: _ctx.icon,
                                class: _normalizeClass(_ctx.iconClass),
                                size: _ctx.iconSize
                              }, null, 8, ["name", "class", "size"]))
                            : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true),
                  _renderSlot(_ctx.$slots, "label", { label: _ctx.label }, () => [
                    _createTextVNode(_toDisplayString(_ctx.label), 1)
                  ], true),
                  (_ctx.facet)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.facet), 1))
                    : _createCommentVNode("", true),
                  (_ctx.hasLabelAfterSlot)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _renderSlot(_ctx.$slots, "label-after", {}, undefined, true)
                      ]))
                    : _createCommentVNode("", true)
                ], 2),
                (_ctx.caption)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["q-item__label gt-sm q-item__label--caption text-caption", _ctx.captionClass])
                    }, _toDisplayString(_ctx.caption), 3))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.toggleValue !== null)
                ? (_openBlock(), _createBlock(_component_tm_field, {
                    key: 1,
                    class: "ml-auto mr-2",
                    "model-value": _ctx.toggleValue,
                    type: "toggle",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:toggleValue', $event)))
                  }, null, 8, ["model-value"]))
                : _createCommentVNode("", true),
              (_ctx.hasBtnSlot)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _renderSlot(_ctx.$slots, "tm-expansion-btn", {}, undefined, true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["q-item__label lt-md q-item__label--caption text-caption", _ctx.captionClass])
            }, _toDisplayString(_ctx.caption), 3)
          ])
        ], true)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["tm-expansion__content", {
          'pl-6': _ctx.contentPadding
        }])
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 2)
      ]),
      _: 3
    }, 8, ["model-value", "default-opened", "group", "duration", "header-class"])
  ], 2))
}