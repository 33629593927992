
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { NumberType } from '@/definitions/services/forms/types'

export default defineComponent({
  props: {
    numberVisible: {
      type: Boolean,
    },
    textMagicNumber: {
      type: Object as PropType<NumberType>,
    },
    headerText: {
      type: String,
    },
  },
})
