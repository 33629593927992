
import type { PropType } from 'vue'
import { defineComponent, computed } from 'vue'
import PreviewLayout from '@/components/pages/services/forms/flows/clicks/appearance/preview/PreviewLayout.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { FlowFieldType, NumberType } from '@/definitions/services/forms/types'

export default defineComponent({
  components: {
    PreviewLayout,
    TmButton,
    TmFormLine,
  },
  props: {
    fields: {
      type: Array as PropType<FlowFieldType[]>,
      required: true,
    },
    textMagicNumber: {
      type: Object as PropType<NumberType>,
    },
    numberVisible: {
      type: Boolean,
    },
    description: {
      type: String,
    },
    btnText: {
      type: String,
    },
  },
  setup(props) {
    const fieldsComputed = computed(() => props.fields.filter((e: FlowFieldType) => e.visible))

    return {
      fieldsComputed,
    }
  },
})
