import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2531d159"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "preview-header" }
const _hoisted_2 = { class: "subhead-semi-bold-16 lh-20 white--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.headerText), 1),
    _createVNode(_component_tm_icon, {
      name: "close",
      class: "white--text",
      size: "xxxLarge"
    })
  ]))
}