import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c44e6782"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-grow-1" }
const _hoisted_2 = { class: "tm-expansion-panel__label" }
const _hoisted_3 = {
  key: 0,
  class: "tm-expansion-panel__label"
}
const _hoisted_4 = { class: "tm-expansion-panel__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_expansion = _resolveComponent("tm-expansion")!

  return (_openBlock(), _createBlock(_component_tm_expansion, { class: "tm-expansion-panel" }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.draggable)
            ? (_openBlock(), _createBlock(_component_tm_icon, {
                key: 0,
                name: "drag_indicator",
                size: "xxxLarge",
                class: "mr-4"
              }))
            : _createCommentVNode("", true),
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_tm_icon, {
                key: 1,
                name: _ctx.icon,
                size: "xxxLarge",
                class: "tm-expansion-panel__icon"
              }, null, 8, ["name"]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "label", { label: _ctx.label }, () => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ], true),
          (_ctx.toggleValue !== null)
            ? (_openBlock(), _createBlock(_component_tm_field, {
                key: 2,
                class: "ml-auto mr-4",
                "model-value": _ctx.toggleValue,
                type: "toggle",
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:toggleValue', $event)))
              }, null, 8, ["model-value"]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.caption)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.caption), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass({
          'px-10': (_ctx.icon || _ctx.draggable) && !_ctx.isSmMax,
        })
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 2)
      ])
    ]),
    _: 3
  }))
}