import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-553271fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-grow-1 align-center" }
const _hoisted_2 = {
  key: 0,
  class: "tm-expansion-white-panel__icon"
}
const _hoisted_3 = { class: "flex-grow-1" }
const _hoisted_4 = { class: "tm-expansion-white-panel__label" }
const _hoisted_5 = {
  key: 0,
  class: "tm-expansion-white-panel__caption"
}
const _hoisted_6 = { class: "tm-expansion-white-panel__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_expansion = _resolveComponent("tm-expansion")!

  return (_openBlock(), _createBlock(_component_tm_expansion, {
    class: "tm-expansion-white-panel",
    "hide-border": ""
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.icon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_tm_icon, {
                name: _ctx.icon,
                size: "xLarge"
              }, null, 8, ["name"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "label", { label: _ctx.label }, () => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ], true)
          ]),
          (_ctx.caption)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.caption), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass({
        'px-14': _ctx.icon && !_ctx.isSmMax,
      })
      }, [
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ], 2)
    ]),
    _: 3
  }))
}